import firebase from "firebase/app";
import "firebase/firestore/memory";
if (typeof window !== "undefined") {
  require("firebase/auth");
  require("firebase/database");
  require("firebase/messaging");
}

if (!firebase.apps.length) {
  const config = {
    apiKey: process.env.firebaseApiKey,
    authDomain: process.env.firebaseAuthDomain,
    databaseURL: process.env.firebaseDatabaseURL,
    projectId: process.env.firebaseProjectId,
    storageBucket: process.env.firebaseStorageBucket,
    messagingSenderId: process.env.firebaseMessagingSenderId,
    appId: process.env.firebaseAppId,
    measurementId: process.env.firebaseMeasurementId,
  };
  firebase.initializeApp(config);
}

export default firebase;
